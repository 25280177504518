import {useSelector} from "react-redux";
import {Modal} from "react-bootstrap";
import React, {useState} from "react";

const Modal_detail = ({sponsor, info, detailshow, setdetailshow}) => {
    const handleClose = () => setdetailshow(false);
    let Name;
    let ProbOrDesc;
    let SecOrType;
    let SoluOrSer;
    let Website;
    let title_ProbOrDesc;
    let title_SecOrType;
    let title_SoluOrSer;

    if (sponsor){
        Name = info.Name;
        title_ProbOrDesc = "Description:"
        ProbOrDesc = info.Description;
        title_SecOrType = "Type:"
        SecOrType = info.Type;
        title_SoluOrSer = "Service:"
        SoluOrSer = info.Service;
        Website = info.Website;
    }else{
        Name = info.entreName;
        title_ProbOrDesc = "Problem we solve:"
        ProbOrDesc = info.problem;
        title_SecOrType = "One sentence introduce:"
        SecOrType = info.introduce;
        title_SoluOrSer = "Solution we provide:"
        SoluOrSer = info.solution;
        Website = info.Website;
    }

    return(
        <Modal show={detailshow} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{Name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <h5 className={""}>
                        {title_SecOrType}
                    </h5>
                    <p className={"detail-font"}>
                        {SecOrType}
                    </p>
                </div>
                <div>
                     <h5 className={""}>
                         {title_ProbOrDesc}
                     </h5>
                    <p className={"detail-font"}>
                        {ProbOrDesc}
                    </p>
                </div>
                <div>
                    <h5 className={""}>
                        {title_SoluOrSer}
                    </h5>
                    <p className={"detail-font"}>
                        {SoluOrSer}
                    </p>
                </div>
                <div>
                    <h5 className={""}>
                        Website:
                    </h5>
                    <p className={"detail-font"}>
                        {Website}
                    </p>
                </div>

            </Modal.Body>

        </Modal>

    )
}
export default Modal_detail;


