import axios from 'axios';

const api = axios.create({ withCredentials: true });
const SPONSORINFO_API = `${process.env.REACT_APP_BACKEND_URL}/sponsor-info`;
//const SPONSORINFO_API = `http://localhost:8000/sponsor-info`;
export const createSponsorInfo = async (info) => {
    const response = await api.post(SPONSORINFO_API, info);
    return response.data;
};
export const updateSponsorInfo = async (info) => {
    const response = await api.put(SPONSORINFO_API, info);
    return response.data;
};
export const findSponsorInfo = async () => {
    const response = await api.get(SPONSORINFO_API);
    const sponsorInfo = response.data;
    return sponsorInfo;
};
export const findSponsorInfoByName = async (Name) => {
    const response = await api.get(`${SPONSORINFO_API}/${Name}`);
    const sponsorInfo = response.data;
    return sponsorInfo;
};

export const findSponsorInfoByType = async (Type) => {
    const response = await api.get(`${SPONSORINFO_API}/Type/${Type}`);
    const sponsorInfo = response.data;
    return sponsorInfo;
};
export const findSponsorInfoByUserId = async (id) => {
    const response = await api.get(`${SPONSORINFO_API}/userid/${id}`);
    const sponsorInfo = response.data;
    return sponsorInfo;
};

export const deleteSponsorInfo = async (userid) => {
    const sponsorInfo = await api.delete(`${SPONSORINFO_API}/${userid}`);
    return sponsorInfo;
};