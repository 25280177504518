import axios from "axios";
const api = axios.create({ withCredentials: true });
const CONNECTION_API = `${process.env.REACT_APP_BACKEND_URL}/connections`;
//const CONNECTION_API = `http://localhost:8000/connections`;


export const createConnection = async (info) => {
    const response = await api.post(CONNECTION_API, info);
    return response.data;
};
export const findAllConnections = async () => {
    const response = await api.get(CONNECTION_API);
    const connections = response.data;
    return connections;
};


export const findConnectionbycedUserId = async (cedid) => {
    const response = await api.get(`${CONNECTION_API}/ceduserid/${cedid}`);
    const connection = response.data;
    return connection;
};

export const findConnectionsByUserId = async (userid) => {
    const response = await api.get(`${CONNECTION_API}/userid/${userid}`);
    const connection = response.data;
    return connection;
};
export const deleteConnection = async (connectionId) => {
    const response = await api.delete(`${CONNECTION_API}/${connectionId}`);
    return response;
};

export const updateConnection = async (connection) => {
    const response = await api.put(`${CONNECTION_API}/${connection._id}`, connection);
    return response;
};