import { createSlice } from "@reduxjs/toolkit";
import {
    deleteUserThunk,
    findAllUsersThunk,
    userThunk,
    logoutThunk,
    registerThunk,
    updateUserThunk,
    findUserByUsernameThunk,
} from "../services/UserThunk";


const usersReducer = createSlice({
                                     name: "users",
                                     initialState: {
                                         loading: true,
                                         users: [],
                                         currentUser: null,
                                         error: null,
                                     },
                                     extraReducers: {
                                         [findUserByUsernameThunk.fulfilled]: (state, action) => {
                                             state.publicProfile = action.payload;
                                         },
                                         [findAllUsersThunk.fulfilled]: (state, action) => {
                                             state.users = action.payload;
                                         },
                                         [userThunk.fulfilled]: (state, action) => {                                         
                                             state.currentUser = action.payload;                                       
                                             state.loading = false;
                                         },
                                         [userThunk.pending]: (state, action) => {
                                             state.currentUser = action.payload;
                                             state.loading = true;
                                         },
                                         [userThunk.rejected]: (state, action) => {
                                      
                                             state.error = action.payload;
                                             state.currentUser = null;
                                             state.loading = false;
                                         },
                                         [logoutThunk.fulfilled]: (state, action) => {
                                             state.currentUser = null;
                                         },
                                         [registerThunk.fulfilled]: (state, action) => {
                                             state.currentUser = action.payload;
                                             state.loading = false;
                                         },
                                         [registerThunk.pending]: (state, action) => {
                                             state.currentUser = action.payload;
                                             state.loading = true;
                                         },
                                         [registerThunk.rejected]: (state, action) => {
                                             state.error = action.payload;
                                             state.currentUser = null;
                                             state.loading = false;
                                         },
                                         [updateUserThunk.fulfilled]: (state, action) => {
                                             const payload = action.payload;
                                             state.loading = false;
                                             const userIdx = state.users.findIndex((u) => u._id === payload._id);
                                             state.users[userIdx] = {
                                                 ...state.users[userIdx],
                                                 ...payload,
                                             };
                                             state.currentUser = state.users[userIdx];
                                         },
                                         [deleteUserThunk.fulfilled]: (state, action) => {
                                             state.loading = false;
                                             state.users = state.users.filter((u) => u._id !== action.payload);
                                         }
                                     },
                                 });

export default usersReducer.reducer;
export const { updateProfile } = usersReducer.actions;
