import {createSlice} from "@reduxjs/toolkit";
import {
   findConnectionbyceduserIdThunk
} from "../services/ConnectionThunk";

const initialState = {
    connectionRequestInfo: [],
    loading: false
};

const ConnectionRequestSlice = createSlice({
                                        name:'collectionRequestReducer',
                                        initialState,
                                        extraReducers:{
                                            [findConnectionbyceduserIdThunk.fulfilled]:
                                                (state, {payload}) =>{
                                                    state.connectionRequestInfo=payload
                                                    state.loading=false
                                                }
                                        }
                                    })

export default ConnectionRequestSlice.reducer;