import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateEntreThunk} from "../services/InfoCollectionThunk";
import {useNavigate} from "react-router-dom";
import axios from "axios";

const EditProfileEntre = () =>{
    const dispatch = useDispatch();
    const [entreinfo,setentreinfo ] = useState();
    const { currentUser,loading } = useSelector((state) => state.users);

    const [introinfo,setintroinfo] = useState();
    const [problem,setproblem] = useState();
    const [solution,setsolution] = useState();
    const [contact,setcontact] = useState();
    const [docres,setdocument] = useState();
    const [entreName,setentreName] = useState();
    const [entreid,setentreid] = useState();
    const ENTREINFO_API = `${process.env.REACT_APP_BACKEND_URL}/entrepreneur-info`;
    const Entre_API =  `${ENTREINFO_API}/userid/${currentUser._id}`
    const navigate = useNavigate();

    const findentreinfo = async () =>{
        const response = await axios.get(Entre_API);
        const entre = response.data[0];
        if (entre){
            await setentreinfo(response.data[0])
            setintroinfo(entre.introduce)
            setproblem(entre.problem)
            setsolution(entre.solution)
            setcontact(entre.contact)
            setentreName(entre.entreName)
            setentreid(entre._id)
        }else{
            setentreinfo({})
        }
    }
    useEffect(() => {
        findentreinfo();
    }, [])


    const fileuploadhandle = (e) =>{
        const doc = e.target.files[0]
        setdocument(doc);
    }
    const buttonhandler = () =>{

        let s = document.getElementById("ShareCheck");
        let shareresult;
        if(s.checked){
            shareresult = true
        }else{
            shareresult = false
        }
        var formdata = new FormData();
        formdata.append("_id",entreid);
        formdata.append("entreName",entreName);
        formdata.append("userid",currentUser._id);
        formdata.append("introduce",introinfo);
        formdata.append("problem",problem);
        formdata.append("solution",solution);
        formdata.append("contact",contact);
        formdata.append("share",shareresult);
        if (docres && docres.length > 0) {
            formdata.append("document", docres);
        }
        dispatch(updateEntreThunk(formdata));

        navigate("/dashboard/profile")
    }

    return(
        <div>
            <div className={"mb-3"}>
                <div className="form-group">
                    {currentUser && <h2 className="col-form-label mt-4">Email: {currentUser.email}</h2>}
                </div>

                <div className="form-group">
                    <label className="col-form-label mt-4" htmlFor="inputDefault">
                        One sentence to introduce your venture</label>
                    <input type="text" className="form-control" placeholder=""
                           id="inputDefault" value={introinfo} onChange={(e)=>setintroinfo(e.target.value)}/>
                </div>
                <div className="form-group">
                    <label className="col-form-label mt-4" htmlFor="inputDefault">Problem you solve
                    </label>
                    <input type="text" className="form-control" placeholder=""
                           id="inputDefault" value={problem} onChange={(e)=>setproblem(e.target.value)}/>
                </div>
                <div className="form-group">
                    <label className="col-form-label mt-4" htmlFor="inputDefault">Solution you provide
                    </label>
                    <input type="text" className="form-control" placeholder=""
                           id="inputDefault" value={solution} onChange={(e)=>setsolution(e.target.value)}/>
                </div>

                <div className="form-group">
                    <label className="col-form-label mt-4" htmlFor="inputDefault">Contact(email,phoneNum)</label>
                    <input type="text" className="form-control" placeholder=""
                           id="inputDefault" value={contact} onChange={(e)=>setcontact(e.target.value)}/>
                </div>
                <div className="form-group mt-4">
                    <input className="form-check-input me-2" type="checkbox" id="ShareCheck"/>
                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                        Can we share your information with innovative resources providers?
                        (When we share your information with them, we will email you to let you know)</label>
                </div>

            </div>
            <div>
                <button type="submit" className="btn btn-primary mt-4 button-size" onClick={()=>buttonhandler()}>Submit</button>
                <button type="submit" className="btn btn-primary mt-4 ms-4 bg-danger button-size" onClick={()=>navigate("/dashboard/profile")}>Back</button>
            </div>
        </div>

    );
};
export default EditProfileEntre;