import {createSlice} from "@reduxjs/toolkit";
import {
    creatSponsorThunk,
    findAllSponsorThunk,
    deleteSponsorThunk,
    findSponsorThunk,
    findSponsorByUserIdThunk, findSponsorByTypeThunk
} from "../services/SponsorInfoThunk";

const initialState = {
    sponsors: [],
    loading: false
};

const sponsorinfoSlice = createSlice({
                                                 name:'entreinfocollectionReducer',
                                                 initialState,
                                                 extraReducers:{
                                                     [findSponsorThunk.fulfilled]:
                                                         (state, {payload}) =>{
                                                             state.sponsors=payload
                                                             state.loading=false
                                                         },
                                                     [findSponsorByTypeThunk.fulfilled]:
                                                         (state, {payload}) =>{
                                                             state.sponsors=payload
                                                             state.loading=false
                                                         },
                                                     [findSponsorByUserIdThunk.fulfilled]:
                                                         (state, {payload}) =>{
                                                             state.sponsors=payload
                                                             state.loading=false
                                                         },
                                                     [findAllSponsorThunk.fulfilled]:
                                                         (state, {payload}) =>{
                                                             state.loading=false
                                                             state.sponsors = payload
                                                         },
                                                     [creatSponsorThunk.fulfilled]:
                                                         (state, {payload}) => {
                                                             state.loading = false
                                                             state.sponsors.push(payload)
                                                         },
                                                     [deleteSponsorThunk.fulfilled]:
                                                         (state, {payload}) => {
                                                             state.loading = false
                                                             state.sponsors = state.sponsor.filter(e => e.id !== payload)
                                                         }
                                                 },
                                                reducers: {
                                                     clearData(state, action) {
                                                         state.sponsors = []
                                                },
                                             }})
export const {clearData} = sponsorinfoSlice.actions;
export default sponsorinfoSlice.reducer;