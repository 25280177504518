import {createAsyncThunk}
    from "@reduxjs/toolkit"
import * as service
    from "./InfoCollectionService.js"
import {findEntrepreneurInfoByUserID, updateEntrepreneurInfo} from "./InfoCollectionService.js";

export const findAllEntreThunk = createAsyncThunk(
    'entrepreneur/findAllInfo',
    async () =>{
        const e = await service.findEntrepreneurInfo()
        return e
    }
)

export const findEntreThunk = createAsyncThunk(
    'entrepreneur/findInfo',
    async (userid) =>{
        const e = await service.findEntrepreneurInfoByID(userid)
        return e
    }
)

export const findEntreByUserIdThunk = createAsyncThunk(
    'entrepreneur/findInfo',
    async (userid) =>{
        const e = await service.findEntrepreneurInfoByUserID(userid)
        return e
    }
)

export const creatEntreThunk = createAsyncThunk(
    'entrepreneur/creatInfo',
    async (info) =>{
        const e = await service.createEntrepreneurInfo(info)
        return e
    }
)
export const updateEntreThunk = createAsyncThunk(
    'entrepreneur/updateInfo',
    async (info) =>{
        const e = await service.updateEntrepreneurInfo(info)
        return e
    }

)
export const deleteEntreThunk = createAsyncThunk(
    'entrepreneur/deleteInfo',
    async (info) =>{
        const e = await service.deleteEntrepreneurInfo(info)
        return e
    }
)