import React from "react";
import {Link, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";

const Navigation = () =>{
    const {pathname} = useLocation();
    const paths = pathname.split('/')
    const active = paths[2];
    const { currentUser } = useSelector((state) => state.users);
    return (
        <div className={"border-0 p-0 m-0 list-group"} style={{width:"240px"}}>
            <Link to={"/dashboard/home"} className={`list-group-item nav-group-item 
                    ${active === 'home'?'active-link':''}`}>
                <span className=""><i className="fa fa-home me-2" aria-hidden="true"></i>Home</span>
            </Link>

            {currentUser && !currentUser.Sponsor &&
             <Link to={"/dashboard/search"} className={`list-group-item nav-group-item
                    ${active === 'search'?'active-link':''}`}>
                <span className=""><i className="fa fa-search me-2" aria-hidden="true"></i>Connect</span>
            </Link>}

            <Link to={"/dashboard/profile"} className={`list-group-item nav-group-item
                    ${active === 'profile'?'active-link':''}`}>
                <span className=""><i className="fa fa-user me-2" aria-hidden="true"></i>Profile</span>
            </Link>
        </div>
    )
}

export default Navigation;