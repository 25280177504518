import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    findConnectionThunk, findConnectionbyceduserIdThunk,
} from "../services/ConnectionThunk";
import Modal_item from "./Modal";
import {Collapse} from "react-bootstrap";

const HomeEntre = () => {
    const { currentUser } = useSelector((state) => state.users);
    const { connectionInfo } = useSelector(state => state.connections);
    const [rotate, setRotate] = useState(false);
    const [open, setOpen] = useState(false);
    const { connectionRequestInfo } = useSelector(state => state.connectionRequests);
    const dispatch = useDispatch();
    useEffect(() => {
        if (currentUser) {
            dispatch(findConnectionbyceduserIdThunk(currentUser._id));
            dispatch(findConnectionThunk(currentUser._id));
        }
    }, [currentUser]);
    const handleToggle = () => {
        setOpen(!open);
        setRotate(!rotate);
    }
    return(
        <div style={{height:"600px"}}>
            <div style={{height:"70%"}}>
                <h3 className={"po-login-title mb-4"}>Your Connection</h3>
                <ul className={"list-group list-scroll"}>
                    {connectionInfo && connectionInfo
                        .filter(nc => nc.cStatus !== 4)
                        .map(nc => <Modal_item data={nc} request={false} />)}
                </ul>
            </div>
            <div style={{height:"30%"}}>
                <button className={"button-none expand-button"} onClick={handleToggle}>
                    <h3 className={"po-title me-2 mb-4 mt-3"}>History</h3>
                    <span id="triangle" className={`triangle ${rotate ? "rotate" : ""}`}></span>
                </button>
                <Collapse in={open}>
                    <ul className={"list-group list-scroll"}>
                        {connectionInfo && connectionInfo
                            .filter(nc => nc.cStatus === 4)
                            .map(nc =><Modal_item data={nc} request={true}/>)}
                    </ul>
                </Collapse>
            </div>
        </div>
    );
};

export default HomeEntre;