import React from 'react';
import { useSelector} from "react-redux";


import HomeEntre from "./Home-entre";
import HomeSponsor from "./Home-sponsor";
const Home = () => {
    const { currentUser } = useSelector((state) => state.users);
    return(
        <div>
            {currentUser && currentUser.Sponsor && <HomeSponsor/>}
            {currentUser && !currentUser.Sponsor && <HomeEntre/>}
        </div>
    );
};

export default Home;