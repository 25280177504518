import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {registerThunk} from "../services/UserThunk";

const Register = () => {
    const { users } = useSelector((state) => state.users);
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [validatePassword, setValidatePassword] = useState("");
    const [error, setError] = useState(null);
    const { currentUser, loading } = useSelector((state) => state.users);
    const dispatch = useDispatch();

    const [Sponsor, setSponsor] = useState(false);
    const handleRegisterBtn = () => {
        if (password !== validatePassword) {
            setError("Password must match.");
            return;
        }
        setError(null);
        const newUser = { username, password, email, Sponsor};

        dispatch(registerThunk(newUser));
        if (currentUser === null) {
            setError("User already exist.");
            return;
        }

    };
    useEffect(()=>{
        if(currentUser){
            navigate("/infoCollection");
        }
    },[currentUser])
    return (
        <div className={"po-outline mt-5 "}>
            <h1 className={"po-login-title"}>Register</h1>
            {!currentUser && !loading && error && <div className="alert alert-danger">{error}</div>}
            <div className={"border rounded-2 mt-3 mb-3 p-2"}>
                <p>
                    +1Dream helps entrepreneurs reach out to related reousrces to help them move
                    their ventures to the next stage. This form is to input your information into
                    +1Dream system. If you give us permission, we will share your infromation to
                    our resources pool such as VC, investors, mentors, incubators, etc. We will connect
                    you with any relevant resources for funding, education,
                    and other useful opportunities from us to support your venture.
                </p>
            </div>
            <div className="form-group">
                <input className="form-control mb-3 po-input-box"
                       value={username} placeholder={"Username"}
                       onChange={(e) => setUsername(e.target.value)}/>
            </div>
            <div className="form-group">
                <input className="form-control mb-3 po-input-box"
                       type ="email"
                       value={email} placeholder={"Email"}
                       onChange={(e) => setEmail(e.target.value)}/>
            </div>
            <div className="form-group">
                <input className="form-control mb-3 po-input-box"
                       type="password"
                       value={password} placeholder={"Password"}
                       onChange={(e) => setPassword(e.target.value)}/>
            </div>
            <div className="form-group">
                <input className="form-control mb-3 po-input-box"
                       type="password"
                       value={validatePassword} placeholder={"Confirm Password"}
                       onChange={(e) => setValidatePassword(e.target.value)}/>
            </div>
            <div className="form-group row mt-4 ms-1">
                <div className="form-check col-4">
                    <label className="form-check-label " htmlFor="optionsRadios2">Entrepreneur</label>
                    <input className="form-check-input" type="radio" name="optionsRadios"
                           id="optionsRadios2" value="Entrepreneur" checked={!Sponsor} onClick={() =>setSponsor(false)}/>
                </div>
                <div className="form-check col-4">
                    <label className="form-check-label " htmlFor="optionsRadios1">Sponsor</label>
                    <input className="form-check-input" type="radio" name="optionsRadios"
                           id="optionsRadios1" value="Sponsor" checked={Sponsor} onClick={() =>setSponsor(true)}/>
                </div>
            </div>
            <button onClick={handleRegisterBtn} className="btn btn-primary w-100 mt-4">
                Register
            </button>
            <button onClick={()=>{navigate("/login");}} className="btn btn-primary w-100 mt-4">
                Back
            </button>
        </div>
    );
};

export default Register;