import { useEffect } from "react";
import {userThunk} from "../services/UserThunk";
import { useDispatch, useSelector } from "react-redux";

const CurrentUser = ({ children }) => {
    const { currentUser } = useSelector((state) => state.users);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(userThunk());
    }, []);
    return children;
};

export default CurrentUser;
