import React, {useState} from "react";
import { useDispatch } from "react-redux";
import {creatEntreThunk} from "../services/InfoCollectionThunk";
import {useNavigate} from "react-router-dom";


const EntreInfoCollection = ( currUser ) =>{
    const dispatch = useDispatch();
    const currentUser = currUser.currUser
    const [introinfo,setintroinfo] = useState();
    const [problem,setproblem] = useState();
    const [solution,setsolution] = useState();
    const [contact,setcontact] = useState();
    const [website,setwebsite] = useState();
    const [docres,setdocument] = useState();
    const [entreName,setentreName] = useState();

    const navigate = useNavigate();

    const fileuploadhandle = (e) =>{
        const doc = e.target.files[0]
        console.log(doc);
        setdocument(doc);
    }
    const buttonhandler = () =>{
        let s = document.getElementById("ShareCheck");

        let shareresult;
        if(s.checked){
            shareresult = true
        }else{
            shareresult = false
        }
        var formdata = new FormData();
        console.log(docres);
        formdata.append("entreName",entreName);
        formdata.append("userid",currentUser._id);
        formdata.append("introduce",introinfo);
        formdata.append("problem",problem);
        formdata.append("solution",solution);
        formdata.append("document",docres);
        formdata.append("contact",contact);
        formdata.append("share",shareresult);
        formdata.append("Website",website);
        dispatch(creatEntreThunk(formdata));

        navigate("/login")
    }

    return(
        <div className={"po-outline"}>
            <div className={"mb-5 mt-5"}>
                <div className="form-group">
                    {currentUser && <h2 className="col-form-label mt-4 po-login-title mb-3">Email: {currentUser.email}</h2>}
                </div>
                <div className="form-group">
                    <input type="text" className="form-control po-input-box mb-3" placeholder="Company Name"
                           id="inputDefault" value={entreName} onChange={(e)=>setentreName(e.target.value)}/>
                </div>
                <div className="form-group">
                    <input type="text" className="form-control po-input-box mb-3" placeholder="One sentence to introduce your venture"
                           id="inputDefault" value={introinfo} onChange={(e)=>setintroinfo(e.target.value)}/>
                </div>
                <div className="form-group">
                   <input type="text" className="form-control po-input-box mb-3" placeholder="Problem you solve"
                           id="inputDefault" value={problem} onChange={(e)=>setproblem(e.target.value)}/>
                </div>
                <div className="form-group">
                    <input type="text" className="form-control po-input-box mb-3 " placeholder="Solution you provide"
                           id="inputDefault" value={solution} onChange={(e)=>setsolution(e.target.value)}/>
                </div>
                <div className="form-group">
                    <input type="file" className="form-control mb-3" style={{width:"468px"}}  placeholder=""
                           id="inputDefault" onChange={(e)=>fileuploadhandle(e)}/>
                </div>
                <div className="form-group">
                    <input type="text" className="form-control po-input-box mb-3" placeholder="Contact Information"
                           id="inputDefault" value={contact} onChange={(e)=>setcontact(e.target.value)}/>
                </div>
                <div className="form-group">
                    <input type="text" className="form-control po-input-box mb-3" placeholder="Website"
                           id="inputDefault" value={website} onChange={(e)=>setwebsite(e.target.value)}/>
                </div>
                <div className="form-group mt-4">
                    <input className="form-check-input me-2 po-selectbox-gray" type="checkbox" id="ShareCheck"/>
                    <label className="form-check-label po-content-gray" htmlFor="flexSwitchCheckDefault">
                        Can we share your information with innovative resources providers?
                        (When we share your information with them, we will email you to let you know)</label>
                </div>

            </div>
            <div>
                <button type="submit" className="btn btn-primary" onClick={()=>buttonhandler()}>Submit</button>

            </div>
        </div>

    );
};
export default EntreInfoCollection;