import UserDetail from "./user-detail";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutThunk } from "../services/UserThunk";
import React, {useEffect} from "react";
import {clearData} from "../reducer/SponsorInfoReducer";


const UserProfile = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { currentUser } = useSelector((state) => state.users);
    const handleLogout = async () => {
        await dispatch(logoutThunk());
        dispatch(clearData());
        await navigate("/login");
        window.location.reload();
    };
    return (
        <>
            {currentUser && <UserDetail sponsor={currentUser.Sponsor}/>}
            <button className="btn btn-danger mt-4" onClick={handleLogout}>
                Logout
            </button>
        </>
    );
};

export default UserProfile;