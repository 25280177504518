import {createSlice} from "@reduxjs/toolkit";
import {
    creatEntreThunk,
    findAllEntreThunk,
    deleteEntreThunk,
    findEntreThunk,
    findEntreByUserIdThunk, updateEntreThunk
} from "../services/InfoCollectionThunk";

const initialState = {
    entreinfo: [],
    loading: false
};

const entreinfocollectionSlice = createSlice({
    name:'entreinfocollectionReducer',
    initialState,
    extraReducers:{
        [findEntreThunk.fulfilled]:
            (state, {payload}) =>{
                state.entreinfo=payload
                state.loading=false
            },
        [findAllEntreThunk.fulfilled]:
            (state, {payload}) =>{
                state.loading=false
                state.entreinfo = payload
            },
        [creatEntreThunk.fulfilled]:
            (state, {payload}) => {
                state.loading = false
                state.entreinfo.push(payload)
            },
        [findEntreByUserIdThunk.fulfilled]:
            (state, {payload}) => {
                state.loading = false
                state.entreinfo.push(payload)
            },
        [deleteEntreThunk.fulfilled]:
            (state, {payload}) => {
                state.loading = false
                state.entreinfo = state.entreinfo.filter(e => e.id !== payload)
            },
        [updateEntreThunk.fulfilled]:
            (state, {payload}) => {
                state.loading = false
                state.entreinfo.push(payload)
            }
    }
})

export default entreinfocollectionSlice.reducer;