import {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { userThunk } from "../services/UserThunk.js";
import { useNavigate } from "react-router-dom";


const Login = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const {currentUser,loading } = useSelector((state) => state.users);
    const dispatch = useDispatch();
    const HandleLoginBtn = () => {
        setError(null);
        const loginUser = { username, password };
        
        dispatch(userThunk(loginUser));
        if(currentUser===null){
            setError("Username and Password didn't match");
            return;
        }
    };
    useEffect(()=>{
        if(currentUser){
            navigate("/dashboard/home");
        }
    })

    return (
        <div className={"po-outline"}>
            <h1 className={"po-login-title mb-3"}>Login</h1>
            {error && !loading && !currentUser && <div className="alert alert-danger">{error}</div>}
            <input
                className="form-control mb-3 po-input-box"
                placeholder={"Username"}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
            />
            <input
                type="password"
                placeholder={"Password"}
                className="form-control mb-3 po-input-box"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <div>
                <a href={"/register"} className={"po-login-create"}>
                    Create a new account
                </a>
                <button onClick={HandleLoginBtn} className="btn btn-primary w-100 mt-3 ">
                    Login
                </button>
            </div>
            {currentUser && <h2>Welcome {currentUser.username}</h2>}

        </div>
    );
};

export default Login;