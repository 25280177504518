import SearchItem from "./SearchItem";
import {useEffect} from "react";
import SearchInput from "./SearchInput";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {findSponsorByTypeThunk} from "../services/SponsorInfoThunk";
import SearchFilter from "./SearchFilter";

const SearchList = () => {
    const { sponsors } = useSelector((state) => state.sponsor);
    const sponsorType = useParams().Type
    const dispatch = useDispatch();
    useEffect(() => {
        if (sponsorType === undefined || sponsorType === '') {
            return;
        }
        dispatch(findSponsorByTypeThunk(sponsorType))
    }, [sponsorType])

    return(
        <>
            <SearchInput/>
            <SearchFilter/>
            <ul className="list-group mt-3 list-scroll">
                {
                    sponsors &&
                    sponsors.slice(0,5).map(sponsor =>
                                                   <SearchItem sponsor = {sponsor}/>
                    )
                }
            </ul>
        </>
    )
}
export default SearchList;