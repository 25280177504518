import {createSlice} from "@reduxjs/toolkit";
import {
    creatConnectionThunk,
    findAllConnectionThunk,
    deleteConnectionThunk,
    findConnectionThunk,
    updateConnectionThunk
} from "../services/ConnectionThunk";

const initialState = {
    connectionInfo: [],
    loading: false
};

const ConnectionSlice = createSlice({
                                                 name:'connection',
                                                 initialState,
                                                 extraReducers:{
                                                     [findConnectionThunk.fulfilled]:
                                                         (state, {payload}) =>{
                                                             state.connectionInfo=payload
                                                             state.loading=false
                                                         },
                                                     [findAllConnectionThunk.fulfilled]:
                                                         (state, {payload}) =>{
                                                             state.loading=false
                                                             state.entreinfo = payload
                                                         },
                                                     [creatConnectionThunk.fulfilled]:
                                                         (state, {payload}) => {
                                                             state.loading = false
                                                             state.connectionInfo.push(payload)
                                                         },
                                                     [deleteConnectionThunk.fulfilled]:
                                                         (state, {payload}) => {
                                                             state.loading = false
                                                             state.connectionInfo = state.connectionInfo.filter(e => e.id !== payload)
                                                         },
                                                    [updateConnectionThunk.pending]:
                                                        (state, {payload}) =>{
                                                            state.loading = true;                                               
                                                        },
                                                     [updateConnectionThunk.fulfilled]:
                                                         (state, {payload}) =>{
                                                             state.loading = false
                                                             const connectionidx = state.connectionInfo
                                                                 .findIndex((t) => t._id === payload._id)

                                                             state.connectionInfo[connectionidx] = {
                                                                 ...payload
                                                             }
                                                         }
                                                 }
                                             })

export default ConnectionSlice.reducer;