import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";

import {useNavigate} from "react-router-dom";
import axios from "axios";
import {updateSponsorThunk} from "../services/SponsorInfoThunk";

const EditProfilePartner = () =>{
    const dispatch = useDispatch();
    const { currentUser } = useSelector((state) => state.users);
    const [name,setname] = useState();
    const [description,setdescription] = useState();
    const [type,settype] = useState([]);
    const [service,setservice] = useState();
    const [funding,setfunding] = useState();
    const [contact,setcontact] = useState();
    const [fee,setfee] = useState();
    const [Sponsorid,setSponsorid] = useState();

    const navigate = useNavigate();
    const SPONSORINFO_API = `${process.env.REACT_APP_BACKEND_URL}/sponsor-info`;
    const SPONSOR_API =  `${SPONSORINFO_API}/userid/${currentUser._id}`

    const findSponsorinfo = async () =>{
        console.log(SPONSOR_API);
        const response = await axios.get(SPONSOR_API);
        const sponsor = response.data;
        console.log(sponsor.Type)
        if (sponsor){
            setname(sponsor.Name)
            setdescription(sponsor.Description)
            settype(sponsor.Type)
            setservice(sponsor.Service)
            setfunding(sponsor.Funding)
            setcontact(sponsor.Contact)
            setfee(sponsor.Fee)
            setSponsorid(sponsor._id)
        }else{
            setname("")
            setdescription("")
            settype("")
            setservice("")
            setfunding("")
            setcontact("")
            setfee("")
        }
    }

    useEffect(() => {
        findSponsorinfo();

    }, [currentUser])


    const sbuttonhandler = () =>{
        const info = {
            "_id":Sponsorid,
            "Name":name,
            "Description":description,
            "Type":type,
            "Service":service,
            "Funding":funding,
            "Contact":contact,
            "Fee":fee,
        }
        dispatch(updateSponsorThunk(info));
        navigate("/dashboard/profile")
    }

    const handleCheckboxChange = (event) => {
        const value = event.target.value;
        if (event.target.checked) {
            settype([...type, value]);
        } else {
            settype(type.filter((option) => option !== value));
        }
    };


    return(
        <div>
            <div className={"mb-3"}>
                <div className="form-group">
                    {currentUser && <h2 className="col-form-label mt-4">Email: {currentUser.email}</h2>}
                </div>
                <div className="form-group">
                    <label className="col-form-label mt-4" htmlFor="inputDefault">
                        Name</label>
                    <input type="text" className="form-control" placeholder=""
                           id="inputDefault" value={name} onChange={(e)=>setname(e.target.value)}/>
                </div>
                <div className="form-group">
                    <label className="col-form-label mt-4" htmlFor="inputDefault">Description
                    </label>
                    <input type="text" className="form-control" placeholder=""
                           id="inputDefault" value={description} onChange={(e)=>setdescription(e.target.value)}/>
                </div>
                <div className="form-group">
                    <label className="col-form-label mt-4" htmlFor="inputDefault">Service
                    </label>
                    <input type="text" className="form-control" placeholder=""
                           id="inputDefault" value={service} onChange={(e)=>setservice(e.target.value)}/>
                </div>
                <div className="form-group" id={"type-check-box"}>
                    <label className="col-form-label mt-4" >Type
                    </label>
                    <div>
                        <input className={"me-1"} type="checkbox" Checked={type.indexOf("Incubator")} id="option1" value={"Incubator"} onChange={handleCheckboxChange}/>
                        <label htmlFor="option1">Incubator/Accelerato</label>
                    </div>
                    <div>
                        <input className={"me-1"} type="checkbox" Checked={type.indexOf("T-partner")} id="option2" value={"T-partner"} onChange={handleCheckboxChange}/>
                        <label htmlFor="option2">Technical partner</label>
                    </div>
                    <div>
                        <input className={"me-1"} type="checkbox" Checked={type.indexOf("Mentor")} id="option3" value={"Mentor"} onChange={handleCheckboxChange}/>
                        <label htmlFor="option3">Mentor</label>
                    </div>
                    <div>
                        <input className={"me-1"} type="checkbox" Checked={type.indexOf("Funding")} id="option4" value={"Funding"} onChange={handleCheckboxChange}/>
                        <label htmlFor="option4">Funding</label>
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-form-label mt-4" htmlFor="inputDefault">Funding
                    </label>
                    <input type="text" className="form-control" placeholder=""
                           id="inputDefault" value={funding} onChange={(e)=>setfunding(e.target.value)}/>
                </div>
                <div className="form-group">
                    <label className="col-form-label mt-4" htmlFor="inputDefault">Contact
                    </label>
                    <input type="text" className="form-control" placeholder=""
                           id="inputDefault" value={contact} onChange={(e)=>setcontact(e.target.value)}/>
                </div>
                <div className="form-group">
                    <label className="col-form-label mt-4" htmlFor="inputDefault">Fee
                    </label>
                    <input type="text" className="form-control" placeholder=""
                           id="inputDefault" value={fee} onChange={(e)=>setfee(e.target.value)}/>
                </div>

            </div>
            <div>
                <button type="submit" className="btn btn-primary mt-4 button-size" onClick={()=>sbuttonhandler()}>Submit</button>
                <button type="submit" className="btn btn-primary mt-4 ms-4 button-size bg-danger" onClick={()=>navigate("/dashboard/profile")}>Back</button>
            </div>
        </div>

    );
};
export default EditProfilePartner