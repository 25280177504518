import React, {useEffect, useState} from 'react';

import {Button, Modal} from "react-bootstrap";
import {useSelector} from "react-redux";

const Header = () => {
    const [show, setShow] = useState(false);
    const { currentUser,loading } = useSelector((state) => state.users);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return(
        <div className={"header-layout"} style={{display:"flex",alignItems:"center"}}>
            <span className="col-10" style={{fontSize: "40px"}}>
                <i className="me-3 col-1 " aria-hidden="true"></i>+1Connector
            </span>
            <div  className="col-2">
                <button className={"border-0 bg-body float-end me-5"} onClick={handleShow}><i className="bi bi-question-circle-fill head-question-mark"></i></button>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>INSTRUCTION</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h3>Home:</h3>
                    {currentUser && !currentUser.Sponsor && <p>Users can view the status and information of ongoing and past connections
                        on the home screen. Through a system summary of previous connections, they can
                        clearly see the recent progress of the enterprise and determine the development goals
                        for the next phase. In the near future, users will also be able to note the achievements of
                        each connection and receive feedback from partners on this interface. This will provide
                        a clearer direction for the development of their own enterprise and help maintain
                        connections.</p>}
                    {currentUser && currentUser.Sponsor &&<p>
                        On the user's home screen, they will receive connection invitations from
                        enterprises. These invitations will include relevant information about the applying
                        enterprise. After reviewing the information, users can choose to connect or disconnect.
                        If they choose to connect, users will enter a schedule provided by the applicant. After
                        deselecting any incompatible time slots, they can submit the feasible time slot. Once
                        confirmed by the +1Connector system, the meeting information for the connection will
                        be updated on this interface. Users simply need to wait for the meeting. If users find the
                        enterprise information uninteresting or if there are no suitable time slots, they can
                        choose to disconnect and provide a reason. This will help us provide more appropriate
                        connection invitations to users in the future.</p>}
                    {currentUser && !currentUser.Sponsor && <h3>Connect:</h3>}
                    {currentUser && !currentUser.Sponsor && <p>In the connect interface, users can select the corresponding resource type
                        based on their development needs, and click "search". The system will display partner
                        information related to their requirements. After reviewing the basic resource information
                        and services provided by the partner, users can choose to connect. After clicking
                        "connect", users will enter a schedule and select feasible time slots before submitting.
                        The information and status of the connection will appear on the home screen. Once the
                        partner confirms the connection, the meeting details and time will be notified to the user
                        through the home screen and email. If the partner declines the connection, the system
                        will continue to help the user establish other new connections until an initial link is
                        established that corresponds to their development needs.</p>}

                    <h3>Profile:</h3>
                    <p>As the user progresses, they can update the relevant information provided during
                        registration on this page. This will help increase the success rate of connection
                        establishment and obtain more relevant resources to better advance to the next stage
                        of development.</p>
                </Modal.Body>

            </Modal>
        </div>
    );
};

export default Header;