import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {creatConnectionThunk, findConnectionThunk} from "../services/ConnectionThunk";
import ScheduleSelector from "react-schedule-selector";
import { Modal } from 'react-bootstrap';
import Modal_detail from "../dashboard/Detailmodel";
const SearchItem = (
    {
       sponsor
    }
) => {
    const { currentUser } = useSelector((state) => state.users);
    const { connectionInfo } = useSelector(state => state.connections);
    const [ show, setshow ] = useState(false);
    const [detailshow,setdetailshow] = useState(false);
    const [ need, setneed] =useState("");
    const [selected,setselected] = useState([]);
    const dispatch = useDispatch();
    let buttonfunction = false;


    const handleShowInfo = () => setdetailshow(true);
    const onclickhandler = () =>{

        const selectedDates = selected.map(time => new Date(time));
        dispatch(creatConnectionThunk({
            cId:currentUser._id,
            cedId:sponsor._id,
            ceduserId: sponsor.userid,
            cedName:sponsor.Name,
            cStatus:0,
            meetingTimes: selectedDates,
            cNode:"",
            timeFinalized: false,
            cNeed:need
                                      }))
        setshow(false)

    }

    useEffect(() => {
        currentUser && dispatch(findConnectionThunk(currentUser._id));
    }, [currentUser])

    connectionInfo.map(b => {
        if (b.cedId === sponsor._id){
            buttonfunction = true;
        }
    })
    return(
        <>

            <Modal show = {show} className={""}>
                <Modal.Header className="modal-header bg-white " style={{width:"900px"}}>
                    <Modal.Title className="modal-title fs-5" id="staticBackdropLabel">Schedule Time</Modal.Title>
                </Modal.Header>
                <Modal.Body className={"bg-white"} style={{width:"900px"}}>
                    <div className={"modal-body"}>
                        <ScheduleSelector
                            selection={selected}
                            numDays={14}
                            minTime={8}
                            maxTime={22}
                            hourlyChunks={1}
                            onChange={setselected}
                        />
                        <div>
                            <label className={"mt-4 me-2 ms-4"} htmlFor={"need-input"}>Please enter your needs:</label>
                            <input id={"need-input"} type="text" style={{width:"500px"}} className={"mt-4"} value={need} onChange={(e)=>{setneed(e.target.value)}}/>
                        </div>
                        <button className="btn btn-primary mt-4 float-end" onClick={onclickhandler}>
                            submit
                        </button>
                        <button className="btn btn-primary mt-4 float-end me-3" onClick={()=>{setshow(false)}}>
                            cancel
                        </button>

                    </div>
                </Modal.Body>
            </Modal>
            {sponsor && <Modal_detail info={sponsor} detailshow={detailshow} setdetailshow={setdetailshow} sponsor={true}/>}

            <li className={"list-group-item dash-record"}>
                <div className="card border-0">
                    <div className="card-body d-flex justify-content-between align-items-center flex-wrap">
                        <div className={"w-50 w-md-50"}>
                            <button className={"border-0 p-0 m-0 bg-body"} onClick={handleShowInfo}><h4 className="card-title">{sponsor.Name}</h4></button>
                            <h6 className="card-subtitle mb-1 text-muted">{sponsor.Type.join('; ')}</h6>
                            <p className="card-text">{sponsor.Description}</p>
                        </div>
                        <div className={"mt-2 mt-md-0 w-50 "}>
                            <button type="button" className="btn btn-primary float-end" disabled={buttonfunction} onClick={()=>{setshow(true)}}>
                                Connect
                            </button>
                        </div>
                    </div>
                </div>
            </li>

        </>
    )
}

export default SearchItem;