import {useState} from "react";
import {useNavigate} from "react-router-dom";

const SearchInput = () =>{

    const [Service, setService] = useState("Incubator")
    const navigate = useNavigate();
    const onSearchHandler = () => {
        if (Service === '' || Service === undefined) {
            return;
        }
        navigate('/dashboard/search/' + Service)
    };


    return(
        <div className={"row mt-1 mb-3 "}>
            <div className="col-7 ">
                <select className="form-select " aria-label="Default select example" onChange={(event)=> setService(event.target.value)}>
                    <option selected value="Incubator">Incubator/Accelerator</option>
                    <option value="T-partner">Technical partner</option>
                    <option value="Mentor">Mentor</option>
                    <option value="Funding">Funding</option>
                </select>
            </div>
            <div className="col-1">
                <button className={"btn btn-primary my-2 my-sm-0"} onClick={onSearchHandler}>Search</button>
            </div>
        </div>
    )
}
export default SearchInput;