import Navigation from "../Navigation/Navigation";
import {Route, Routes} from "react-router";
import SearchList from "../SearchItem/SearchList";
import Home from "./Home";
import UserProfile from "../UserProfile/profile";
import EditProfileEntre from "../UserProfile/Edit-profile-entre";
import EditProfilePartner from "../UserProfile/Edit-profile-partner";
import React from "react";
import Header from "./Header";
const Dashboard = () => {
    return(
        <div style={{height:"100%"}}>
            <Header/>
            <div className={"row dash-main-part w-100 border-0 p-0 m-0"}>
                <div className={"col-2 nav-bar-layout border-0 p-0 m-0"}>
                    <Navigation active="home" />
                </div>
                <div className={"col-8 p-5 dash-home"}>
                    <Routes>
                        <Route path={"/home"}
                            element={<Home />} />
                        <Route path={"/search"}
                            element={<SearchList />} />
                        <Route path={"/search/:Type"}
                            element={<SearchList />} />
                        <Route path={"/profile"}
                            element={<UserProfile />} />
                        <Route path={"/edit-entre-profile"}
                            element={<EditProfileEntre />} />
                        <Route path={"/edit-sponsor-profile"}
                            element={<EditProfilePartner />} />
                    </Routes>
                </div>
            </div>
            {/* for footer */}
            {/*<div>*/}
            {/*   */}
            {/*</div>*/}
        </div>
    )
}
export default Dashboard;