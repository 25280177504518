import axios from "axios";
const api = axios.create({ withCredentials: true });
const ENTREINFO_API = `${process.env.REACT_APP_BACKEND_URL}/entrepreneur-info`;
//const ENTREINFO_API = `http://localhost:8000/entrepreneur-info`;
export const createEntrepreneurInfo = async (info) => {
    const response = await api.post(ENTREINFO_API, info);
    return response.data;
};
export const findEntrepreneurInfo = async () => {
    const response = await api.get(ENTREINFO_API);
    const bookmark = response.data;
    return bookmark;
};
export const findEntrepreneurInfoByID = async (userid) => {
    const response = await api.get(`${ENTREINFO_API}/${userid}`);
    const bookmark = response.data;
    return bookmark;
};
export const findEntrepreneurInfoByUserID = async (userid) => {
    const response = await api.get(`${ENTREINFO_API}/userid/${userid}`);
    const bookmark = response.data;
    return bookmark;
};
export const deleteEntrepreneurInfo = async (userid) => {
    const response = await api.delete(`${ENTREINFO_API}/${userid}`);
    return response;
};
export const updateEntrepreneurInfo = async (info) => {
    const response = await api.put(ENTREINFO_API, info);
    return response;
};
