
export const throttle = (fn, delay) => {
    let timer;
    return () =>{
        if (timer){

            return
        }
        timer = setTimeout(()=>{
            fn();
            timer=null;
        }, delay)
    }
}

export const debouncee = (fn, delay) =>{
    let timer;
    return () =>{
        if (timer){
            clearTimeout(timer)
        }
        timer = setTimeout(()=>{
            fn();
            timer=null;
        },delay)

    }
}

export const timeConverter = (timestamp) => {
    var a = new Date(timestamp);
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours() >= 10 ? a.getHours(): "0"+a.getHours();
    var min = a.getMinutes() >= 10 ? a.getMinutes(): "0"+ a.getMinutes();
    var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min;
    return time;
}
