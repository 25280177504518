import { Provider } from "react-redux";
import {BrowserRouter} from "react-router-dom";
import {Routes, Route} from "react-router";
import {configureStore} from "@reduxjs/toolkit";
import entreinfocollectionreducer from "./plusOneDream/reducer/InfoCollectionReducer.js";
import usersreducer from "./plusOneDream/reducer/LoginReducer.js";
import sponsorInfoReducer from "./plusOneDream/reducer/SponsorInfoReducer";
import connectionReducer from "./plusOneDream/reducer/ConnectionReducer";
import collectionRequestReducer from "./plusOneDream/reducer/ConnectionRequestReducer"
import CurrentUser from "./plusOneDream/UserProfile/current-user";
import Login from "./plusOneDream/loginAndRegister/Login";
import Register from "./plusOneDream/loginAndRegister/register";
import Dashboard from "./plusOneDream/dashboard/Dashboard";
import InfoCollection from "./plusOneDream/InfoCollection/InfoCollection";

const store = configureStore({
     reducer: {
         entreinfocollection: entreinfocollectionreducer, users:usersreducer, sponsor:sponsorInfoReducer, connections:connectionReducer, connectionRequests:collectionRequestReducer
     },
 });

function App() {
  console.log("app.js")
  return (
      <Provider store={store} style={{ height: "100%" }}>
          <CurrentUser style={{height:"0%"}}>
          <div style= {{backgroundImage: "linear-gradient(to bottom, #F0F8FF, #FFFFFF)", height: "100%", width: '100%'}}>
            <BrowserRouter>
                <div style={{height: "100%"}} >
                  <Routes>
                      <Route index
                             element={<Login/>}/>
                      <Route path = "/login"
                             element={<Login/>}/>
                      <Route path = "/register"
                             element={<Register/>}/>
                      <Route path = "/dashboard/*"
                             element={<Dashboard/>}/>
                      <Route path = "/infoCollection"
                             element={<InfoCollection/>}/>
                  </Routes>
                </div>
            </BrowserRouter>
          </div>
          </CurrentUser>
      </Provider>
  );
}

export default App;
