import React, {useState} from "react";
import { useDispatch } from "react-redux";
import {useNavigate} from "react-router-dom";
import {creatSponsorThunk} from "../services/SponsorInfoThunk";


const SponsorInfoCollection = (currUser) =>{
    const dispatch = useDispatch();
    const currentUser = currUser.currUser
    const [name,setname] = useState();
    const [description,setdescription] = useState();
    const [type,settype] = useState([]);
    const [service,setservice] = useState();
    const [funding,setfunding] = useState();
    const [contact,setcontact] = useState();
    const [website,setwebsite] = useState();
    const [fee,setfee] = useState();
    const navigate = useNavigate();

    const sbuttonhandler = () =>{
        const info = {
            "userid": currentUser._id,
            "Name":name,
            "Description":description,
            "Type":type,
            "Service":service,
            "Funding":funding,
            "Contact":contact,
            "website":website,
            "Fee":fee,
        }
        dispatch(creatSponsorThunk(info));
        navigate("/login")
    }
    const skiphandler = ()=>{
        const info = {
            "userid": "",
            "Name":"",
            "Description":"",
            "Type":"",
            "Service":"",
            "Funding":"",
            "Contact":"",
            "website":"",
            "Fee":"",
        }
        dispatch(creatSponsorThunk(info));
        navigate("/login")
    }

    const handleCheckboxChanged = (event) => {
        const value = event.target.value;
        if (event.target.checked) {
            settype([...type, value]);
        } else {
            settype(type.filter((option) => option !== value));
        }
        console.log(type)
    };

    return(
        <div className={"po-outline mb-5 mt-5"}>
            <div className={""}>
                <div className="form-group">
                    {currentUser && <h2 className="col-form-label mt-4 po-login-title mb-3">Email: {currentUser.email}</h2>}
                </div>
                <div className="form-group">
                    <input type="text" className="form-control po-input-box mb-3" placeholder="Name"
                           id="inputDefault" value={name} onChange={(e)=>setname(e.target.value)}/>
                </div>
                <div className="form-group">
                    <input type="text" className="form-control po-input-box mb-3" placeholder="Description"
                           id="inputDefault" value={description} onChange={(e)=>setdescription(e.target.value)}/>
                </div>
                <div className="form-group">
                    <input type="text" className="form-control po-input-box mb-3" placeholder="Service"
                           id="inputDefault" value={service} onChange={(e)=>setservice(e.target.value)}/>
                </div>
                <div className="form-group">
                   <input type="text" className="form-control po-input-box mb-3" placeholder="Funding"
                           id="inputDefault" value={funding} onChange={(e)=>setfunding(e.target.value)}/>
                </div>
                <div className="form-group">
                    <input type="text" className="form-control po-input-box mb-3" placeholder="Contact (E-mail, Phone)"
                           id="inputDefault" value={contact} onChange={(e)=>setcontact(e.target.value)}/>
                </div>
                <div className="form-group">
                    <input type="text" className="form-control po-input-box mb-3" placeholder="Website"
                           id="inputDefault" value={website} onChange={(e)=>setwebsite(e.target.value)}/>
                </div>
                <div className="form-group">
                    <input type="text" className="form-control po-input-box mb-3" placeholder="Fee"
                           id="inputDefault" value={fee} onChange={(e)=>setfee(e.target.value)}/>
                </div>
                <div className="form-group" id={"type-check-box"}>
                    <label className={"mb-2"}>Type</label>
                    <div className={"mb-2"}>
                        <input className={"me-1 po-selectbox-gray"} type="checkbox" id="option1" value={"Incubator"} onChange={handleCheckboxChanged}/>
                        <label className={"po-content-gray"} htmlFor="option1">Incubator/Accelerato</label>
                    </div>
                    <div className={"mb-2"}>
                        <input className={"me-1 po-selectbox-gray"} type="checkbox" id="option2" value={"T-partner"} onChange={handleCheckboxChanged}/>
                        <label className={"po-content-gray"} htmlFor="option2">Technical partner</label>
                    </div>
                    <div className={"mb-2"}>
                        <input className={"me-1 po-selectbox-gray"} type="checkbox" id="option3" value={"Mentor"} onChange={handleCheckboxChanged}/>
                        <label className={"po-content-gray"} htmlFor="option3">Mentor</label>
                    </div>
                    <div className={"mb-2"}>
                        <input className={"me-1 po-selectbox-gray"} type="checkbox" id="option4" value={"Funding"} onChange={handleCheckboxChanged}/>
                        <label className={"po-content-gray"} htmlFor="option4">Funding</label>
                    </div>
                </div>

            </div>
            <div className={"mt-4"}>
                <button type="submit" className="btn btn-primary " onClick={()=>sbuttonhandler()}>Submit</button>
                <button type="submit" className="btn btn-primary ms-3" onClick={()=>skiphandler()}> Skip </button>
            </div>
        </div>

    );
};
export default SponsorInfoCollection;