import {Modal} from "react-bootstrap";
import ScheduleSelector from "react-schedule-selector";
import React, {useEffect, useState} from "react";
import {useDispatch,useSelector} from "react-redux";
import axios from "axios";
import { updateConnectionThunk} from "../services/ConnectionThunk";
import {timeConverter} from "../utils/tool";
import Modal_detail from "./Detailmodel";
const Modal_item = ({data, request}) =>{
    const [selected,setselected] = useState(data.meetingTimes);
    const [shows,setshows] = useState(false);
    const [edetailshow,setedetailshow] = useState(false);
    const [sdetailshow,setsdetailshow] = useState(false);
    const [startdate,setstartdate] = useState(data.meetingTimes[0]);
    const [entreinfo,setentreinfo ] = useState();
    const [sponsorinfo,setsponsorinfo ] = useState();
    const {connectionInfo, loading } = useSelector((state) => state.connections);
    const euserId = data.cId;
    const sponserId = data.ceduserId;
    const dispatch = useDispatch();
    const BASE_URL = "https://plus1-web-backend.azurewebsites.net";
    const Entre_API = BASE_URL + "/entrepreneur-info/userid/"+euserId;
    const Sponsor_API = BASE_URL +"/sponsor-info/userid/"+sponserId;
    const findentreinfo = async () =>{
        const response1 = await axios.get(Entre_API);
        if (response1.data.Response === 'False'){
            setentreinfo([])
        }else{
            setentreinfo(response1.data[0])
        }
    }
    const findsponsorinfo = async () =>{
        const response2 = await axios.get(Sponsor_API);

        if (response2.data){
            setsponsorinfo(response2.data)
        }else{
            setsponsorinfo([])
        }

    }
    useEffect(() => {
        findentreinfo();
        findsponsorinfo();
    }, [euserId,sponserId,dispatch])
    const onclickhandler = () =>{
        const selectedDates = selected.map(time => new Date(time));
        const info = {
            ...data,
            cStatus: 1,
            meetingTimes: selectedDates
        }
        dispatch(updateConnectionThunk(info))
        setshows(false)
        if (!loading){
            window.location.reload();
        }
 
    }


    const Disconnecthandler = () =>{
        const info = {
            ...data,
            cStatus: 3,
        }
        dispatch(updateConnectionThunk(info))
        window.location.reload();
    }
    const eDetailButtonHandler = () =>{
        setedetailshow(true);
    }
    const sDetailButtonHandler = () =>{
        setsdetailshow(true);
    }
    return(
        <>
            <Modal show = {shows} className={""}>
                <Modal.Header className="modal-header bg-white " style={{width:"900px"}}>
                    <Modal.Title className="modal-title fs-5" id="staticBackdropLabel">Schedule Time</Modal.Title>
                </Modal.Header>
                <Modal.Body className={"bg-white"} style={{width:"900px"}}>
                    <div className={"modal-body "}>
                        <ScheduleSelector
                            startDate={startdate}
                            selection={selected}
                            numDays={14}
                            minTime={8}
                            maxTime={22}
                            hourlyChunks={1}
                            onChange={setselected}
                        />

                        <button className="btn btn-primary mt-4 float-end" onClick={onclickhandler}>
                            submit
                        </button>
                        <button className="btn btn-primary mt-4 float-end me-3" onClick={()=>{setshows(false)}}>
                            cancel
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
            {entreinfo && <Modal_detail info={entreinfo} detailshow={edetailshow} setdetailshow={setedetailshow} sponsor={false}/>}
            {sponsorinfo && <Modal_detail info={sponsorinfo} detailshow={sdetailshow} setdetailshow={setsdetailshow} sponsor={true}/>}
            <li className={"list-group-item dash-record"}>
                <div className="card border-0">
                    <div className="card-body d-flex justify-content-between">
                        <div className={"w-70"}>
                            {request &&
                             <button className={"border-0 p-0 m-0 bg-body"} onClick={eDetailButtonHandler}>
                                <h6 className="card-subtitle mb-1 text-muted mb-3">Entreprenuer: {entreinfo && entreinfo.entreName}</h6>
                            </button>}
                            {!request &&
                             <button className={"button-none"} onClick={sDetailButtonHandler}>
                                <h6 className="card-subtitle mb-1 text-muted mb-3">Partner: {data && data.cedName}</h6>
                             </button>}
                            <p>Requirement: {data.cNeed}</p>
                            <p>{data.cStatus === 0 && !request && "Awaiting confirmation" }</p>
                            <p>{data.cStatus === 1 && !request && "Partner confirmed!!!Waiting our staff finalize" }</p>
                            <p>{data.cStatus === 2 && !request && "Finalized Meeting Time: "+timeConverter(data.meetingTimes[0]) }</p>
                            <p>{data.cStatus === 3 && !request && "Rejected"}</p>
                            <p>{data.cStatus === 0 && request && "New request" }</p>
                            <p>{data.cStatus === 1 && request && "Waiting our staff finalize" }</p>
                            <p>{data.cStatus === 2 && request && "Finalized Meeting Time: "+timeConverter(data.meetingTimes[0])}</p>
                            <p>{data.cStatus === 3 && request && "Disconnected"}</p>
                        </div>
                        <div className={"mt-4"}>
                            {request && data.cStatus===0 && <button type="button" className="btn color-accept me-3" onClick={()=>{setshows(true)}}>
                                Connect
                            </button>}
                            {request && data.cStatus===0 && <button type="button" className="btn color-reject" onClick={()=>{Disconnecthandler()}}>
                                Disconnect
                            </button>}
                        </div>
                    </div>
                </div>
            </li>
        </>


    )
}
export default Modal_item;