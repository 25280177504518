import {createAsyncThunk}
    from "@reduxjs/toolkit"
import * as service
    from "./ConnectionService.js"


export const findAllConnectionThunk = createAsyncThunk(
    'connection/findAllInfo',
    async () =>{
        const e = await service.findAllConnections()
        return e
    }
)

export const findConnectionThunk = createAsyncThunk(
    'connection/findconnect',
    async (userid) =>{
        const e = await service.findConnectionsByUserId(userid)
        return e
    }
)

export const findConnectionbyceduserIdThunk = createAsyncThunk(
    'connection/findconnectrequest',
    async (cedid) =>{
        const e = await service.findConnectionbycedUserId(cedid)
        return e
    }
)

export const creatConnectionThunk = createAsyncThunk(
    'connection/creatInfo',
    async (info) =>{
        const e = await service.createConnection(info)
        return e
    }
)

export const deleteConnectionThunk = createAsyncThunk(
    'connection/deleteInfo',
    async (cid) =>{
        const e = await service.deleteConnection(cid)
        return e
    }
)

export const updateConnectionThunk = createAsyncThunk(
    'connection/updateInfo',
    async (connection) =>{
        const e = await service.updateConnection(connection)
        return e
    }
)

