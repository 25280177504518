import {createAsyncThunk}
    from "@reduxjs/toolkit"
import * as service
    from "./SponsorInfoService.js"


export const findAllSponsorThunk = createAsyncThunk(
    'sponsor/findAllInfo',
    async () =>{
        const e = await service.findSponsorInfo()
        return e
    }
)

export const findSponsorThunk = createAsyncThunk(
    'sponsor/findInfo',
    async (Name) =>{
        const e = await service.findSponsorInfoByName(Name)
        return e
    }
)

export const findSponsorByTypeThunk = createAsyncThunk(
    'sponsor/findInfoByType',
    async (Type) =>{
        const e = await service.findSponsorInfoByType(Type)
        return e
    }
)

export const findSponsorByUserIdThunk = createAsyncThunk(
    'sponsor/findInfoByUserId',
    async (Service) =>{
        const e = await service.findSponsorInfoByUserId(Service)
        return e
    }
)

export const creatSponsorThunk = createAsyncThunk(
    'sponsor/creatInfo',
    async (info) =>{
        console.log(info)
        const e = await service.createSponsorInfo(info)
        return e
    }
)
export const updateSponsorThunk = createAsyncThunk(
    'sponsor/updateInfo',
    async (info) =>{
        console.log(info)
        const e = await service.updateSponsorInfo(info)
        return e
    }
)

export const deleteSponsorThunk = createAsyncThunk(
    'sponsor/deleteInfo',
    async (info) =>{
        const e = await service.deleteSponsorInfo(info)
        return e
    }
)