import {useSelector} from "react-redux";
import SponsorInfoCollection from "./SponsorInfo";
import EntreInfoCollection from "./EntrepreneurInfo";

const InfoCollection = () =>{
    const { currentUser } = useSelector((state) => state.users);
    const sponsor = currentUser.Sponsor;
    return(
        <>
            {currentUser && sponsor && <SponsorInfoCollection currUser ={currentUser} />}
            {currentUser && !sponsor && <EntreInfoCollection currUser ={currentUser} />}
        </>
    )
}

export default InfoCollection